import React from 'react'
import {
  Drawer,
  DrawerContent,
  Flex,
  Box,
  Text,
  VStack,
  Center,
} from '@chakra-ui/react'
import Link from '../../components/Link'
import BackgroundImage from '../../components/BackgroundImage'
import { responsive } from '../../contexts/responsive'
import program from './program.png'
import cart from './cart.png'
import suggest from './suggest.png'
import website from './website.png'
import bgCircle from './bgCircle.png'
import Header from '../Header'

export const labels = [
  {
    zh: '暑期限定活動方案',
    en: 'PROGRAM',
    to: '/#program',
    src: program
  },
  {
    zh: '購物車',
    en: 'CART',
    to: '/cart',
    src: cart
  },
  {
    zh: '家長推薦',
    en: 'SUGGEST',
    to: '/#suggest',
    src: suggest
  },
  {
    zh: '跳養官網',
    en: 'PARENTINGBOOM',
    href: 'https://www.parentingboom.com/',
    src: website
  },
]

const Menu = ({ isOpen, onClose }) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="full"
    >
      <DrawerContent bg="custom.bgPrimary">
        <Header menu menuClose={onClose} />
        <Box width={'100%'} maxW="36em" height="100%" pt={responsive('8.125em', '14.5em')} overflow={'hidden'} pos="relative" mx="auto" bg="custom.skin">
          <Box width="222px" pos="absolute" top="110px" right={'-100px'} transform="rotate(-1.19deg)" opacity={0.6}>
            <BackgroundImage src={bgCircle} ratio={1} />
          </Box>
          <Box width="222px" pos="absolute" top="350px" left={'-70px'} transform="rotate(98.96deg)">
            <BackgroundImage src={bgCircle} ratio={1} />
          </Box>
          <Center>
            <VStack spacing={7}>
              {labels.map((label, i) => (
                <Flex as={Link} onClick={onClose} href={label.href} to={label.to} width="100%" alignItems={'center'} pos="relative" key={i}>
                  <Box width={responsive('4em', '6em')}><BackgroundImage src={label.src} ratio={label.ratio || 1} /></Box>
                  <Box ml={responsive("1.375em", '2.5em')} flex={1}>
                    <Text fontWeight={500} fontSize={responsive('1.0625em', '1.25em')} whiteSpace="nowrap" color="custom.buttonBlue">{label.zh}</Text>
                    <Text fontWeight={700} fontSize={responsive('0.875em', '1.25em')} color="custom.bucksGreen">{label.en}</Text>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </Center>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default Menu
