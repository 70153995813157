import { css } from '@emotion/react'
import "focus-visible/dist/focus-visible"

import { mediaStyle } from './src/contexts/responsive'

export default css`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Noto+Serif+TC:wght@300;400;500;700&family=Roboto:wght@400;500;700;900&display=swap');
  body {
    min-width: 100%;
    min-height: 100%;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    display: none;
  }

  ${mediaStyle}
`;
