import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'

import Box from '../../components/Box'
import useHeader from '../../contexts/header/useHeader'

import Header from '../Header'
import { responsive } from '../../contexts/responsive'
import Footer from '../Footer'

const Layout = ({ children, ...props }) => {
  const { headerHeight, hideHeader } = useHeader()
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              siteUrl
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { title, description, siteUrl },
        },
      }) => {
        return (
          <>
            <Helmet>
              <html lang="zh-Hant-TW" />
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta property="og:url" content={siteUrl} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta
                property="og:image"
                content={`${siteUrl}/preview_logo.jpg`}
              />
              <meta
                name="facebook-domain-verification"
                content="5cflngspjtmcyccjy9m4q17qcuwzo9"
              />
            </Helmet>
            {!hideHeader && <Header siteTitle={title} />}
            <Box
              as="main"
              minH={'100vh'}
              bg="custom.bgPrimary"
              pt={responsive('3.75em', '5.25em')}
            >
              {children}
              <Footer />
            </Box>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
