import { theme, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"
import { get } from "lodash";
import { responsive } from "../../contexts/responsive";

import breakpoints from '../../contexts/responsive/breakpoints'

const font = 'Roboto, Noto Sans TC, Arial, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  body: font,
  mono: "Menlo, monospace",
}

const primary = 'blue'
const secondary = 'green'
const danger = 'red'

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    primary: get(theme.colors, `${primary}.500`),
    secondary: get(theme.colors, `${secondary}.500`),
    danger: get(theme.colors, `${danger}.500`),
    text: get(theme.colors, 'black'),
    custom: {
      skyBlue: '#285DAE',
      buttonBlue: '#3D5B9E',
      skin: '#FCF5EA',
      bucksGreen: '#72984B',
      yellow: '#F2CF50',
      bgYellow: '#F3D25A',
      bgPrimary: '#F3EEDF',
      listColor: '#717171',
      bgGray: '#E0DFDF',
      red: '#D00303'
    }
  },
  breakpoints: createBreakpoints(breakpoints),
  components: {
    Button: {
      defaultProps: {},
      variants: {
        solid: {
          bg: '#F3D25A',
          fontSize: responsive('1.125rem', '1.5rem'),
          width: '100%',
          minW: 'auto',
          height: 'auto',
          borderRadius: 'full',
          py: '0.75rem',
          _hover: {
            bg: '#F3D25A',
            _disabled: {
              bg: '#F3D25A',
            }
          },
          _active: {
            bg: '#F3D25A'
          },
        },
        skyBlue: {
          py: '1rem',
          color: "white",
          bg: "#285DAE",
          _hover: { bg: '#285DAE' },
          borderRadius: 'full',
          fontSize: responsive('1.125rem', '1.5rem'),
          width: '100%',
          minW: 'auto',
          height: 'auto',
        }
      },
    },
    Container: {
      baseStyle: {
        maxW: 'container.lg',
      },
    },
  },
}

const customTheme = extendTheme(overrides)

export default customTheme
