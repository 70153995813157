import React, { useMemo } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import BackgroundImage from '../components/BackgroundImage'

import logo from './footerlogo.png'

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear() ,[])
  return (
    <Flex pos="relative" maxW={'36em'} mx="auto" px="0.75em" bg="custom.skin" py="0.375em" justifyContent={'space-between'} alignItems="center">
      <Box width="5.75em"><BackgroundImage src={logo} ratio={276 / 115} /></Box>
      <Text fontWeight={500} fontSize="0.75em">笛飛兒EQ教育版權所有 © {year}</Text>
    </Flex>
  )
}

export default Footer
