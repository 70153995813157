import React from 'react'
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Icon, useDisclosure } from '@chakra-ui/react'
import Box from '../components/Box';
import Flex from '../components/Flex';
import Link from '../components/Link';
import BackgroundImage from '../components/BackgroundImage';

import logo from './logo.png'
import { responsive } from '../contexts/responsive';
import Menu from './Menu';

const Header = ({ siteTitle, menu, menuClose, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
  <Flex
    as="header"
    position="fixed"
    bg="custom.skin"
    top={0}
    left={0}
    right={0}
    alignItems="center"
    justifyContent={responsive('space-between')}
    zIndex={20}
    borderBottom="2px solid"
    borderColor="custom.yellow"
    width={'100%'}
    px="1em"
    py="0.5em"
    maxW={'36em'}
    mx="auto"
    {...props}
  >
    <Box width={responsive('15em', '23em')}>
      <Link onClick={menuClose} to="/">
        <BackgroundImage src={logo} ratio={478 / 86} />
      </Link>
    </Box>
    <Box onClick={menu ? menuClose : onOpen} cursor="pointer" color="custom.skyBlue">
      {menu ? <Icon as={IoMdClose} w={responsive(6, 10)} h={responsive(6, 10)} /> : <Icon as={AiOutlineMenu} w={responsive(6, 10)} h={responsive(6, 10)} />}
    </Box>
    <Menu isOpen={isOpen} onClose={onClose} />
  </Flex>
)}

export default Header
